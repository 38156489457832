<!--
LIMITED BY USiNG a single string input/output
Searchable auto-complete works like a dream
v-model
label? label for search field
apiPath API path for the searches to be applied
params? Additional Params to be passed
itemValue? what value to return, default Object
formatEntry? use this method to craft a friendly name for each entry (default id)
exp. x => Object.assign(x, { friendlyName: x.name })
NOTES: When using this in a filters reduce the friendlyName and itemValue to a single string
This will allow the string to be reflected correctly
If you are returning an object then a object will need to be passed in

When using within filters

Do something like this

          <search-autocomplete
            v-model="createdBy"
            label="Created By"
            api-path="/api/users/"
            item-value="username"
            multiple
            :format-entry="x => ({ friendlyName: x.username, username: x.username })"
          />

It makes it possible to base everything around a STRING
Then you would want to set your chip up like this

{ name: 'Yard ID', prop: 'yardName', type: String, query: 'yard__name__iexact' },
The limitation here is that they have to be the same and that it will be passed in the URL
but it's a small caveat

-->
<template>
  <v-autocomplete
    :value="value"
    :loading="isLoading"
    :search-input.sync="search"
    :label="label"
    :items="getResults"
    item-text="friendlyName"
    :item-value="itemValue"
    :return-object="!itemValue"
    :clearable="clearable"
    :multiple="multiple"
    :error-messages="errorMessages"
    @input="$emit('input', $event)"
  />
</template>

<script>
import axios from 'axios'

export default {
  name: 'SearchAutocomplete',
  props: {
    value: {
      validator: function (value) {
        return typeof value === 'object' || typeof value === 'string'
      },
      default: null
    },
    label: {
      type: String,
      default: 'Search'
    },
    apiPath: {
      type: String,
      required: true
    },
    params: {
      type: Object,
      default: () => Object()
    },
    itemValue: {
      type: String,
      default: null
    },
    // should add a property friendlyName
    formatEntry: {
      type: Function,
      default: x => Object.assign(x, { friendlyName: x.id })
    },
    clearable: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: Array,
      default: Array
    }
  },
  data: function () {
    return {
      isLoading: false,
      search: '',
      resultList: this.initResultList(),
      timeout: null
    }
  },
  watch: {
    search (val, prev) {
      // This fix prevents the duplicate searches after select by looking at the friendly name.
      if (!val) return
      if (this.value && this.value.hasOwnProperty('friendlyName') && val === this.value.friendlyName) return
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.lookup(val)
      }, 600)
    }
  },
  computed: {
    getResults () {
      // Initial results when value is an array (multi-select enabled)
      // Allows results that are not in search results to still be viewed and edited
      // when search results don't contain the selected values
      if (Array.isArray(this.value)) {
        return this.resultList.map(this.formatEntry).concat(this.value)
      }
      return this.resultList.map(this.formatEntry)
    }
  },
  methods: {
    initResultList () {
      // return an empty array
      if (!this.value) return []
      // generate { friendlyName itemValue }
      if (typeof this.value === 'string' && typeof this.itemValue === 'string') {
        let obj = { friendlyName: this.value }
        obj[this.itemValue] = this.value
        return [obj]
      }
      // handle friendlyName formatting for multiple initial results
      if (Array.isArray(this.value)) {
        return this.value.map(x => this.formatEntry(x))
      }
      // wrap the basic object and format the friendlyName
      return [this.formatEntry(this.value)]
    },
    async lookup (search) {
      this.isLoading = true
      let params = this.params
      params.search = search
      const response = await axios.get(this.apiPath, { params })
      this.isLoading = false
      // works with results or base
      if (!response.data) {
        this.$store.commit('setSnackbarError', 'Lookup failed: no data')
        return
      }
      if (response.data.hasOwnProperty('results')) {
        this.resultList = response.data.results
      } else {
        this.resultList = response.data
      }
    }
  }
}
</script>
